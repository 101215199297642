.dashboardAd {
    width: 100%;
    min-height: 100vh;
    background-color: #1e1f20;
    background-image: url('../../images/pattern-bg1.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    color: #fff;
}

.chartDiv {
    width: 80%;
    height: 400px;
    padding: 20px;
    backdrop-filter: blur(5px);
    border-radius: 5px;
    background-color: #3c3c3c57;
}

.insideChartt {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.singleCard {
    width: 300px;
    height: 130px;
    border-radius: 5px;
    padding-top: 1%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin: 5px 15px 20px 15px;
    box-shadow: 0px 0px 8px 0px #5a5b5c52;
    background-color: #5a5b5c57;
    backdrop-filter: blur(5px);
}

.topC {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0% 4% 4% 4%;
}

.bottomC {
    cursor: pointer;
    width: 100%;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #80808070;
}

.leftCard svg {
    font-size: 45px;
}

.rightCard {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: center;
}

.view {
    width: 100%;
    text-align: left;
    padding: 10px;
    font-size: 12px;
}


.cardsDash {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 40px 0 60px 0;
}

.singleCard .bottomC {
    color: #d8d8d8;
}

.bottomC svg {
    margin-right: 3%;
}
@media(max-width:1000px) {
    .chartDiv {
        width: 98%;
        height: 300px;
    }
}