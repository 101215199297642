.Menu {
    padding-top: 65px;
    background-color: #1c1c1c;
}

.slider {
    width: 100%;
    height: 600px;
}

.carousel.slide {
    width: 100%;
    height: 100%;
}

.carousel-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.ImgS {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
}

.active.carousel-item-start.carousel-item {
    display: block;
    width: 100%;
    height: 100%;
}

.active.carousel-item {
    width: 100%;
    height: 100%;
}

.carousel-item {
    width: 100%;
    height: 100%;
}

.carousel-caption {
    padding-bottom: 12.25rem;
}

img.lineMenu {
    width: 160px;
    margin-bottom: 40px;
}

.carousel-caption h2 {
    color: #edd15e!important;
}

.lineAf::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(247, 228, 109);
    background: linear-gradient(90deg, #e3c48e 0%, #edd15e 50%, #e3c48e 100%);
    z-index: -1;
}

.lineAf {
    position: relative;
    height: 2px;
}


.menuItems {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: #1c1c1c;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    color: white;
}

.titleMenu {
    margin: 20px;
    font-size: 40px;
}

img.lineMenuA {
    width: 120px;
}

.subTitleM {
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 25px;
    color: #edd15e!important;
}

.check_menu_sub{
    display: flex !important;
    flex-direction: column;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 0%;
    left: 5%;
}



#active_switch{
    display: inline !important;
}

label span {
    background: #fff;
    border-radius: 10px;
    height: 3px;
    margin: 3px 0;
    transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

}


span:nth-of-type(1) {
    width: 50%;

}
label:hover span:nth-of-type(1) {
    width: 100%;
}
label:hover span:nth-of-type(3) {
    width: 100%;
}



span:nth-of-type(2) {
    width: 100%;
}


span:nth-of-type(3) {
    width: 75%;

}


input[type="checkbox"] {
    display: none;
}

.offcanvas {
    background: #1c1c1c !important;
    color: white !important;
}



.offcanvas-title.h5 {
    opacity: 0;
}



.imgMenu {
    width: 65%;
    height: 100px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
}

img.canMI {
    width: 100%;
    object-fit: cover;
}

nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
    background-color: #1c1c1c !important;
}

.nav-link.active {
    color: #e3c48e !important;
}

.nav-link {
    color: white !important;
}

.nav-item.show.dropdown a#basic-nav-dropdown {
    color: #e3c48e !important;
}

.navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
    width: 100% !important;
}

.dropdown-menu {
    background-color: #1c1c1c !important;
    border: none;
}

.dropdown-item {
    color: white !important;
    margin-left: 7px;
}

.navbar-nav .dropdown-menu {
    position: static !important;
}

a.dropdown-item:hover {
    background-color: #222222;
    transition: 0.3s;
}

.dropdown-toggle::after {
    margin-left: 0;
    right: 10px;
    position: absolute;
    top: 23px 
}

.nav-item.dropdown {
    width: 100%;
}

.menuC {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 100%;
}
.menuC::before {
    /*
    content: "";
    width: 2px;
    height: 80%;
    top: 12%;
    position: absolute;
    left: 6%;
    background: linear-gradient(0deg, rgb(21 32 21) 0%, rgb(18 74 35) 25%, rgb(18 74 35) 75%, rgb(21 32 21) 100%);
    */
}
.container{
    position: relative;
}
a.nav-link {
    border-bottom: 1px solid #222222;
    margin: 5px 0 5px 0;
}
a.nav-link:hover{
    background-color: #222222;
    transition: 0.3s;
    border-radius: 5px;
}
.imgMenu::after {
    content: "";
    width: 84%;
    height: 2px;
    top: 82%;
    /* background: linear-gradient(0deg, #e3c48e 0%, rgb(18 74 35) 10%, #e3c48e 95%); */
    position: absolute;
    left: 9%;
    background: linear-gradient(90deg, rgb(21 32 21) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%, rgb(21 32 21) 100%);
}
.imgMenu::before {
    content: "";
    width: 84%;
    height: 2px;
    top: 90%;
    /* background: linear-gradient(0deg, #e3c48e 0%, rgb(18 74 35) 10%, #e3c48e 95%); */
    position: absolute;
    left: 9%;
    background: linear-gradient(90deg, rgb(21 32 21) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%, rgb(21 32 21) 100%);
}
.menuC::after {
    /*
    content: "";
    width: 2px;
    height: 80%;
    top: 12%;
    position: absolute;
    left: 9%;
    background: linear-gradient(0deg, rgb(21 32 21) 0%, rgb(18 74 35) 25%, rgb(18 74 35) 75%, rgb(21 32 21) 100%);*/
}

nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
    width: 80%;
}

.offcanvas-body{
    position: relative;
}

.itemMImg {
    width: 260px;
    height: 300px;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    padding: .4rem;
}

.itemMImg::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); 
    transition: background-color 0.3s; 
}

.itemMImg:hover::before {
    background-color: rgba(0, 0, 0, 0.5);
}

.itemMImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    transition: transform 0.3s ease-in-out; 
}

.itemMImg:hover img {
    transform: scale(1.1); 
}
.singleItemM {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 13px;
    margin: 0% 35px 60px 35px;
    border-radius: 10px;
    background-color: #3535354f;
    box-shadow: inset 0px 0px 20px 1px #32312f;
    min-width: 21%;
}
.allItemsM {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
}
.displayItems {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 100px 0px 50px 0px;
}
.itemMname {
    font-size: 24px;
    text-align: center;
    line-height: 100%;
    height: 30px;
    margin: 4%;
}
.itemMdesc {
    color: #737272;
    font-size: 14px;
    padding-top: .5rem;
}
.itemMdprice {
    margin: 8px;
    font-size: 20px;
    color: #edd15e!important;
    font-weight: 600;
}
.collapse:not(.show) {
    display: block;
}
@media(max-width:800px) {
    label {
        top: 300%;
    }
    .subTitleM{
        margin-top: 0;
    }
}