@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');



@font-face {
  font-family: "Vladimir";
  src: local("Vladimir"),
   url("../src/fonts/VLADIMIR.TTF") format("truetype");
 }

* ::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body{
  margin: 0;
  font-family: "Exo 2", sans-serif;
}
::selection {
  background-color: #e3c48e; /* Set your desired background color */
  color: #000; /* Set your desired text color */
}
