.login_cont{
    width: 100vw;
    height: 100vh;
    display: flex;
    background-image: url('../../images/aboutUs.jpg');
    background-repeat: no-repeat;
    background-size: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.gradient-form{
    height: fit-content !important;
}

.first_whi{
    background: white;
    border-radius: 10px;
}

.first_row{
    max-width: 50vw !important;
}

.logininput > .form-control~.form-label{
    position: absolute !important;
    left: 0.75rem !important;
}

.gradient-custom-2 {
    /* fallback for old browsers */
    background: #fccb90;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, #eeb824, #1b442c);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, #eeb8248c, #1b442c);
}
.errMsg{
    font-size: .8rem;
    color: red;
}
.succMsg{
    font-size: .8rem;
    color: green;
}
@media (min-width: 768px) {
.gradient-form {
    height: 100vh !important;
}
}
@media (min-width: 769px) {
.gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
}
}

@media (max-width: 1000px){
    .first_row{
        max-width: 80vw !important;
    }
}

@media (max-width: 450px){
    .first_row{
        max-width: 95vw !important;
    }
}