.contact {
    padding-top: 65px;
    width: 100%;
    min-height: 100vh;
}

.contactUsS {
    background-image: url('../images/contact2.jpg');
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.titleContact {
    color: #d8d8d8;
    font-size: 50px;
    font-weight: 500;
    position: relative;
}

.locatonText {
    min-height: 65vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    background-color: #1c1c1c;
    color: #d8d8d8;
    padding: 30px;
}

.leftTxtL {
    width: 60%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.singleInfoL {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 22px;
    padding-bottom: 30px;
    padding-left: 60px;
    border-bottom: 1px solid #353434;
    font-size: 14px;
}

.singleIconL {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.leftsingleIconL {
    min-width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.leftsingleIconL svg {
    margin-right: 10px;
}

.leftsingleIconL span {
    font-size: 16px;
}

.rightTxtL {
    width: 40%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.rightsingleIconL span {
    margin-right: 40px;
}

.hoursW span:nth-of-type(1) {
    min-width: 150px;
}

.hoursW {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

@media(max-width:1400px) {
    .leftsingleIconL {
        min-width: 35%;
    }
}

@media(max-width:1100px) {
    .leftsingleIconL {
        min-width: 50%;
    }

    .locatonText {
        min-height: 65vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        padding: 60px 30px 60px 30px;
    }

    .leftTxtL {
        width: 100%;
    }

    .rightTxtL {
        width: 100%;
    }
}

@media(max-width:900px) {
    .singleInfoL {
        width: 80%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 22px;
        padding-bottom: 30px;
        padding-left: 5px;
        border-bottom: 1px solid #353434;
        font-size: 14px;
    }
    .singleIconL {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }
    .leftTxtL {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .leftsingleIconL {
        min-width: 100%;
        text-align: center;
        margin-bottom: 45px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .rightsingleIconL {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        align-content: space-around;
    }
    .leftsingleIconL svg {
        margin-right: 10px;
        color: #e0c659;
    }
    .leftsingleIconL span {
        font-size: 16px;
        width: auto;
        color: #e0c659;
    }
    .rightsingleIconL span {
        margin-right: 0;
        width: auto;
    }
}
@media(max-width:450px) {
    .locatonText {
        padding: 60px 5px 60px 5px;
    }
    .rightTxtL {
        padding-left: 0;
        padding-right: 0;
    }
    .rightsingleIconL {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        align-content: space-around;
    }
    .titleContact {
        font-size: 30px;
    }
    .hoursW {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 5px;
        margin-bottom: 9px;
    }
    .hoursW span:nth-of-type(1) {
        width: auto;
        text-align: center;
    }
    .hoursW span:nth-of-type(2) {
        width: auto;
        text-align: center;
    }
}