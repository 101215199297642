.footer{
    width: 100%;
    height: 300px;
   
}

.insideFooter{
    background-image: url('../images/footer2.jpg');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position-y: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: #8e8e8e;
    padding: 80px 40px 20px 40px;
}
.insideFooter::before {
    content: "";
    width: 100%;
    height: 2px;
    height: 85px;
    position: absolute;
    left: 0%;
    top: 0%;
    background: linear-gradient(0deg, rgb(28 28 28 / 2%) 0%, rgb(28 28 28 / 26%) 35%, rgb(28 28 28 / 75%) 65%, rgb(28 28 28) 100%);
}
.titleFooterE {
    color: white;
    font-size: 16px;
    margin: 20px;
}
.leftFooter {
    width: 25%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.leftFooter span{
    font-size: 12px;
}
.leftFooter img{
    width: 60%;
    height: 100%;
    object-fit: cover;
}
.middleFooter {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.navsFooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
}
.rightFooter {
    width: 25%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.rightFooter svg:hover{
    scale: 1.05;
    transition: 0.3s;
    color: #edd15e;
}
.singleFooterNav {
    font-size: 14px;
    margin: 16px;
    margin-top: 0;
    cursor: pointer;
    color: white;
}
.singleFooterNav:hover{
    transition: 0.3s;
    color: #edd15e;
}
.rightFooter svg{
    margin: 5px;
}
.leftFooter span {
    font-size: 12px;
    padding: 0 12px 0 12px;
    text-align: center;
}
.threeBot {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    margin-top: 22px;

}
.threeTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 50px;
}
.threeTop span:nth-of-type(1){
    width: 25%;
}
.threeTop span:nth-of-type(2){
    width: 50%;
}
.threeTop span:nth-of-type(3){
    width: 25%;
}
.threeTop span{
    color: white;
    text-align: center;
}
.readMoreF {
    color: #edd15e!important;
    font-size: 12px;
    margin-top: 5px;
}
.navsFooter::after {
    content: "";
    width: 40%;
    height: 2px;
    top: 120%;
    position: absolute;
    left: 30%;
    background: linear-gradient(90deg, rgb(31 32 36) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%,rgb(31 32 36) 100%);
}
.navsFooter::before {
    content: "";
    width: 60%;
    height: 2px;
    top: 150%;
    position: absolute;
    left: 20%;
    background: linear-gradient(90deg, rgb(21 20 25 / 50%) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%,rgb(21 20 25 / 50%) 100%)
}
.copyRightContent {
    width: 100%;
    text-align: center;
}
.copyRightContent span{
    font-size: 12px;
    margin-top: 20px;
}
@media(max-width:1200px) {
    .footer {
        width: 100%;
        height: auto;
    }
    .leftFooter span {
        font-size: 12px;
        padding: 0 12px 0 12px;
        text-align: center;
        width: 80%;
    }
}

@media(max-width:1000px) {
    .footer {
        width: 100%;
        height: auto;
    }
    .rightFooter {
        width: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 30px 30px 20px 30px;
    }
    .middleFooter {
        width: 400px;
    }
    .leftFooter {
        width: 400px;
    }
    .rightFooter{
        width: 80%;
    }
    .titleFooterE {
        color: white;
        font-size: 16px;
        margin: 20px;
    }
    .threeBot {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        margin-top: 22px;
    }

    .threeTop span:nth-of-type(3) {
        width: 25%;
        display: none;
    }
    .threeTop span:nth-of-type(1) {
        width: 400px;
    }
    .threeTop span:nth-of-type(2) {
        width: 400px;
    }
    .leftFooter span {
        width: 90%;
    }
}
@media(max-width:900px) {
    .leftFooter {
        width: 90%;
        margin-bottom: 30px;
    }
    .middleFooter {
        width: 90%;
    }
    .rightFooter {
        width: 90%;
    }
    .leftFooter span {
        width: 60%;
    }
    .rightFooter .titleFooterE {
        display: none;
    }
    .middleFooter .titleFooterE {
        display: none;
    }
    .insideFooter{
        padding-top: 4%;
    }
}
@media(max-width:600px) {
    .leftFooter span {
        width: 80%;
    }
}
@media(max-width:450px) {
    .middleFooter {
        width: 100%;
    }
    .insideFooter {
        padding: 4% 2% 4% 2%;
    }
    .navsFooter {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .navsFooter::after {
        content: "";
        width: 0;
        height: 0;
        top: 120%;
        position: absolute;
        left: 30%;
        background: linear-gradient(90deg, rgb(31 32 36) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%,rgb(31 32 36) 100%);
    }
    .navsFooter::before {
        content: "";
        width: 0;
        height: 0;
        top: 120%;
        position: absolute;
        left: 30%;
        background: linear-gradient(90deg, rgb(31 32 36) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%,rgb(31 32 36) 100%);
    }
}
