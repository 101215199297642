.sideBarC {
    width: 100%;
    height: 65px;
    background-color: #0e0e0e;
    display: flex;  
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 10px 50px 10px 50px;
}
.imgLogoA {
    width: 190px;
    height: auto;
}
.imgLogoA img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.leftSide {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.rightSide img{
    width: 80%;
    height: 80%;
    object-fit: cover;
    border-radius: 50px;
}
.rightSide {
    width: 45px;
    height: 45px;
    padding: 0px;
    border-radius: 50px;
    background-color: #c2c2c2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px 0px #9593938f;
}
.navsAdminP a {
    text-decoration: none;
    color: white;
}
.navsAdminP {
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    height: 100%;
}
.navsAdminP a {
    text-decoration: none;
    color: white;
    height: 100%;
    margin: 0 10px 0 10px;
    padding: 0 15px 0 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 14px;
}
.navsAdminP a svg {
    font-size: 20px;
    margin-right: 6px;
}
a.activeLinkAdmin.active {
    position: relative;
    width: 100%;
    height: 100%;
}

a.activeLinkAdmin.active:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 4px;
    background-color: #e0c659;
    top: -20%;
    left: 0;
}
a.activeLinkAdminM.active {
    position: relative;
    width: 100%;
    height: auto;
    color: #e0c659;
}


.navsAdminP a:hover:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 4px;
    background-color: #e0c659;
    top: -20%;
    left: 0;
}
.burgerMobile{
    display: none;
}
.burgerMobile svg{
    font-size: 25px;
    color: white;
}
div#titleMob {
    opacity: 1;
}
.navsAdminPP{
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    margin-left: 15px;
    height: 100%;
    justify-content: space-between;
}
.insAd {
    width: 100%;
}
.navsAdminPP a {
    text-decoration: none;
    color: white;
    margin: 0 10px 0 10px;
    padding: 0 15px 0 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    font-size: 14px;
    padding: 7px 0 14px 0;
}
.navsAdminPP a svg{
    margin-right: 10px;
}
@media(max-width:1000px) {
    .navsAdminP {
        display: none;
    }
    .rightSide{
        display: none;
    }
    .sideBarC{
        padding: 10px 29px 10px 0px;
    }
    .burgerMobile{
        display: block;
    }
    .imgLogoA {
        width: 160px;
        height: auto;
    }
}