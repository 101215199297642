.eventsAdm{
    width: 100%;
    min-height: 100vh;
    background-color: #1e1f20;
    background-image: url('../../images/pattern-bg1.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
}
.displayEvent{
    width: 100%;
    min-height: 100vh;
    background-color: #1e1f20;
    background-image: url('../../images/pattern-bg1.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    padding: 20px 50px 20px 50px;
}

.infoAe {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}
label.form-label {
    color: #fff;
}
.infoAe span{
    width: auto;
    text-align: center;
}
.infoAe span:first-of-type {
    font-size: 16px;
    margin-bottom: 7px;
    font-weight: 700;
    color: white;
}
.infoAe span:last-of-type{
    font-size: 12px;
}
@media(max-width:450px) {
    .displayEvent{
        padding: 20px 10px 20px 10px
    }
}