.Reservation {
    padding-top: 65px;
    width: 100%;
    min-height: 100vh;
}

.displayEvent {
    padding-top: 65px;
    width: 100%;
    min-height: 100vh;
}
.text-green{
    color: green !important
}
.text-red{
    color: red !important;
}
button.btn.btn-primary {
    background-color: #edad22;
    border: none;
}

.reservationC {
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
}

.modal {
    --bs-modal-footer-border-color: none !important;
    --bs-modal-header-border-color: none !important;
}

.titleReservation span {
    font-size: 40px;
    text-transform: uppercase;
}

.reservationCE {
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-left: 5%;
}

.arrowBack {
    position: absolute;
    z-index: 5;
    top: 27px;
    cursor: pointer;
}

.boothTable{
    min-width: fit-content;
    border-radius: 3px !important;
    padding: .5rem .5rem;
}


.reservationSkica {
    width: 100%;
    height: auto;
    position: relative;
}

.reservationSkica img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

span.reservedTable {
    opacity: 1;
    background-color: #9d9d9d !important;
}

.reservedTable {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

span.insideReservedTable {
    position: absolute !important;
    top: -84% !important;
    left: 0% !important;
}

.reservationSkica span {
    position: absolute !important;
    background-color: #edad22;
    color: black;
    width: 30px !important;
    height: 30px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
}

.reservationSkica span:hover {
    scale: 1.1;
    transition: 0.3s;
}

.reservationSkica span:nth-of-type(-n+19),
.reservationSkica span:nth-of-type(51),
.reservationSkica span:nth-of-type(52),
.reservationSkica span:nth-of-type(53),
.reservationSkica span:nth-of-type(34),
.reservationSkica span:nth-of-type(35),
.reservationSkica span:nth-of-type(36),
.reservationSkica span:nth-of-type(37),
.reservationSkica span:nth-of-type(38),
.reservationSkica span:nth-of-type(21)
.reservationSkica span:nth-of-type(22),
.reservationSkica span:nth-of-type(31),
.reservationSkica span:nth-of-type(30),
.reservationSkica span:nth-of-type(33)
{
    display: none;
}

.reservationSkica span:nth-of-type(1) {
    top: 17%;
    left: 29%;

}

.reservationSkica span:nth-of-type(2) {
    top: 17%;
    left: 38%;

}

.reservationSkica span:nth-of-type(3) {
    top: 74%;
    left: 34%;

}

.reservationSkica span:nth-of-type(4) {
    top: 30%;
    left: 29%;

}

.reservationSkica span:nth-of-type(5) {
    top: 30%;
    left: 38%;
}

.reservationSkica span:nth-of-type(6) {
    top: 24%;
    left: 48%;

}

.reservationSkica span:nth-of-type(7) {
    top: 24%;
    left: 58%;

}

.reservationSkica span:nth-of-type(8) {
    top: 34%;
    left: 48%;

}

.reservationSkica span:nth-of-type(9) {
    top: 34%;
    left: 58%;

}

.reservationSkica span:nth-of-type(10) {
    top: 34%;
    left: 67%;

}

.reservationSkica span:nth-of-type(11) {
    top: 62%;
    left: 26%;

}

.reservationSkica span:nth-of-type(12) {
    top: 62%;
    left: 34%;

}

.reservationSkica span:nth-of-type(13) {
    top: 74%;
    left: 26%;


}

.reservationSkica span:nth-of-type(14) {
    top: 49%;
    left: 30%;

}

.reservationSkica span:nth-of-type(15) {
    top: 74%;
    left: 59%;


}

.reservationSkica span:nth-of-type(16) {
    top: 62%;
    left: 59%;


}

.reservationSkica span:nth-of-type(17) {
    top: 62%;
    left: 56%;


}

.reservationSkica span:nth-of-type(18) {
    top: 74%;
    left: 51%;


}

.reservationSkica span:nth-of-type(19) {
    top: 74%;
    left: 43%;


}

.eventInfoImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.singleINfoE span {
    font-size: 14px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.singleINfoE span:first-of-type {
    margin-bottom: 5px;
}

.adressEvent {
    cursor: pointer;
}

.adressEvent:hover {
    text-decoration: underline;

}

.infoAbEvent {
    width: 400px;
    padding: 25px 15px 30px 15px;
    color: white;
    background-color: #0e0e0e;
    margin-top: 23px;
    border-radius: 1px;
    box-shadow: 0px 0px 4px 1px black;
    color: #d8d8d8;
}

.socialMediaE svg {
    margin: 0 5px 0 5px;
    cursor: pointer;
}

.socialMediaE svg:hover {
    transition: 0.3s;
    color: white;
}

.reserveWtext {
    width: 100%;
    height: auto;
    position: relative;
    text-align: center;
    margin-bottom: 55px;
}

div#contained-modal-title-vcenter {
    color: #edad22;
    font-weight: 700;
}

.reserveW {
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
    width: 100%;
}

label {
    position: relative;
    width: 100%;
}

label.form-label {
    position: relative !important;
    width: 100% !important;
    left: 0 !important;
}

.formsEvent {
    margin-top: 50px;
    margin-bottom: 50px;
}

.modal-content {
    color: white;
    background-color: #000000de;
    backdrop-filter: blur(5px);
    padding: 20px;
    box-shadow: 0px 3px 7px 1px black;
}

.leftSk {
    color: #d8d8d8;
    width: 1000px;
    height: auto;
    position: relative;
    margin-top: 23px;
}

.btn-close {
    color: black !important;
}

.singleINfoE {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #1c1c1c;
    padding: 10px;
    margin-bottom: 10px;
    /* box-shadow: 1px 1px 1px; */
}

.eventInfo {
    width: 30%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.eventInfoC {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: -137px;
    position: relative;
    z-index: 20;
    padding: 20px;
}

.eventInfoImg {
    width: 400px;
    height: 588px;
}

.tableReservations {
    width: 100%;
    min-height: 100vh;
    background-color: #1c1c1c;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}

.eventInfo {
    width: 40%;
}

.arrowBack svg {
    font-size: 50px;
    color: #edad22;
    backdrop-filter: blur(4px);
}

.dateEvent span {
    font-size: 14px;
    width: auto !important;
}

.dateEvent {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    width: 200px;
    padding: 13px 15px 13px 15px;
    border-radius: 10px;
    background-color: #edad22;
    color: black;
    box-shadow: 0px 4px 12px 0px black;
}

.titleReservation {
    color: #d8d8d8;
    font-size: 50px;
    font-weight: 500;
    position: relative;
}

.imgR {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    filter: brightness(0.6)
}

.imgR img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.EventsContent {
    background-color: #1c1c1c;
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
    color: #d8d8d8;
}

.insideEvents {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.singleEvent {
    width: 250px;
    height: 430px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin: 20px;
    cursor: pointer;
}

.eventArtist {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #111111;
}

.imageEvent {
    width: 100%;
    height: 350px;
    position: relative;
}

.imageEvent img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.eventArtist span:nth-of-type(1) {
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    margin-bottom: 5px;
}

.eventArtist span:nth-of-type(2) {
    font-size: 12px;
    text-align: center;
    color: #8e8e8e;
}

.titleReservationPP {
    color: #d8d8d8;
    font-size: 35px;
    font-weight: 500;
}

.absoluteEvent {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #edd15ebd;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    opacity: 0;
    backdrop-filter: blur(1px);
}

.bookReserve {
    background-color: #111111;
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px black;
}

.imageEvent:hover .absoluteEvent {
    opacity: 1;
    transition: 0.5s;
}

.imageEvent:hover .singleEvent {
    transition: 0.3s !important;
    scale: 1.1 !important;
}

.singleEvent:hover {
    transition: 0.3s !important;
    scale: 1.1 !important;
}

.insideAbE {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

@media(max-width:1500px) {
    .leftSk {
        width: 900px
    }
}

@media(max-width:1100px) {

    .reservationSkicaR span {
        width: 20px !important;
        height: 20px !important;
        font-size: 12px;
    }
    
    .leftSk {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .tableReservations {
        width: 100%;
        min-height: 100vh;
        background-color: #1c1c1c;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
        overflow: hidden;
    }

    .reservationSkica {
        width: 80%;
        height: auto;
        position: relative;
    }

    .eventInfo {
        width: 100%;
    }

    .eventInfoC {
        margin-top: 30px;
    }
}

@media(max-width:800px) {
    .insideEvents {
        width: 100%;
    }

    .EventsContent {
        padding: 40px 1% 40px 1%;
    }

    .tableReservations {
        padding: 20px 1% 20px 1%;
    }
}

@media(max-width:700px) {
    .reservationSkica {
        width: 100%;
        height: auto;
        position: relative;
    }
}

@media(max-width:650px) {
    .reservationSkica span {
        width: 20px !important;
        height: 20px !important;
        font-size: 12px;
    }
    .reservationSkicaR span {
        width: 20px !important;
        height: 20px !important;
        font-size: 12px;
    }
    .reservationSkica {
        width: 123%;
    }
}

@media(max-width:450px) {
    .titleReservation {
        font-size: 40px;
    }

    .eventInfoImg {
        width: 90%;
        height: 480px;
    }

    .infoAbEvent {
        width: 90%;
    }

    .singleINfoE span:first-of-type {
        margin-bottom: 5px;
        width: 100%;
    }

    .eventInfoC {
        padding: 0;
    }

    .singleINfoE span {
        font-size: 14px !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }
    
}