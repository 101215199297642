.Navigation {
    position: absolute;
    width: 100%;
}

.navsAndIcons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    min-width: 60%;
}
#menuMob .offcanvas-title.h5 {
    opacity: 1;
}


.navs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.relNav {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    color: white;
    background-color: #0e0e0e;
}

img.logo {
    width: 150%;
    object-fit: cover;
    padding-left: 2rem;
}
img.logoMobile{
    width: 235%;
    height: 100%;
    object-fit: cover;
    margin-left: -37px;
}

.divLogo {
    width: 150px;
    height: 65px;
    padding-left: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divLogoMobile {
    width: 120px;
    height: 65px;
    padding-left: 30px;

}

.navs span {
    margin: 0 15px 0 15px;
    font-size: 16px;
    font-weight: 500;

    cursor: pointer;
}

.lnks a {
    text-decoration: none;
    margin: 0 15px 0 15px;
    font-size: 16px;
    font-weight: 500;
    color: white;
    cursor: pointer;
}

.navs a {
    text-decoration: none;
    margin: 0 15px 0 15px;
    font-size: 16px;
    font-weight: 500;
    color: white;
    cursor: pointer;
}

.activeLink {
    color: #edd15e !important;
}

.Icons svg {
    margin: 0 5px 0 5px;
    font-size: 23px;
}
.IconsMobile svg{
    margin: 0 5px 0 5px;
    font-size: 27px;
    margin: 0 10px 0 10px;
}
.IconsMobile svg:hover {
    scale: 1.05;
    transition: 0.3s;
    color: #edd15e;
}

.Icons svg:hover {
    scale: 1.05;
    transition: 0.3s;
    color: #edd15e;
}

.burgerBtn {
    display: none;
}

.navsAndIconsMobile {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    min-width: 60%;
}

.navsMobile a {
    text-decoration: none;
    margin: 14px 15px 15px 15px;
    font-size: 16px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    font-size: 16px;
    width: 85%;
}

.navsMobile {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 0 15px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid grey;
}

.navsAndIconsMobile {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    min-width: 60%;
}
#menuMobile .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(0.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}
#menuMobile .button.btn-close {
    background-color: none;
    color: black !important;
}
.button .btn-close {
    background-color: none !important;
    color: black !important;
}
.btn-close {
    --bs-btn-close-color:white!important
}
.btn-close {
    opacity: 1;
    color: black !important;
}


@media(max-width:1000px) {
    .navsAndIcons {
        display: none;
    }
    img.logo {
        margin-left: -40px;
        width: 180px;
    }

    .burgerBtn {
        display: block;
        width: 50px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .burgerBtn svg {
        font-size: 30px;
    }

    #menuMob .offcanvas-title.h5 {
        opacity: 1;
    }

    #menuMob .offcanvas-header button.btn-close {
        opacity: 1;
    }


}
@media(max-width:500px) {
    .burgerBtn {
        width: 50px;
    }
    img.logo {
        margin-left: -40px;
        width: 180px;
    }
}