.home {
    background-image: url("../images/main.jpg");
    width: 100%;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.landH {
    width: 100%;
    height: 100%;
    padding-top: 5%;
    background-color: rgba(0, 0, 0, 0.568);
}

.loremTxt {
    width: 40%;
    text-align: center;
    font-size: 16px;
    margin-top: 60px;
    margin-bottom: 70px;
}

.secondHomeC {
    background-color: #1c1c1c;
    width: 100%;
    height: auto;
    color: #d8d8d8;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 60px 20px 120px 20px;
}

.aboutCaf {
    font-size: 30px;
    color: #e0c659;
    font-weight: 600;
    position: relative;
}

.aboutCaf::after {
    content: "";
    width: 90%;
    height: 2px;
    top: 120%;
    position: absolute;
    left: 5%;
    background: linear-gradient(90deg, rgb(31 32 36) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%, rgb(31 32 36) 100%);
}

.aboutCaf::before {
    content: "";
    width: 60%;
    height: 2px;
    top: 140%;
    position: absolute;
    left: 20%;
    background: linear-gradient(90deg, rgb(31 32 36) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%, rgb(31 32 36) 100%);
}

.titleWelcome {
    font-weight: 600;
    font-size: 20px;
}

.buttonsH {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.mT {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 50px;
}

.bigT {
    font-size: 60px;
    /* color: white; */
    line-height: 80px;
    font-weight: bold;
}


.right {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 75%;
    color: white;
}

.homeBtn:first-of-type {
    border: 1px solid #e0c659;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    background-color: #1c1c1c61;
}

.homeBtn:last-of-type {
    border: 1px solid #e0c659;
    backdrop-filter: blur(1px);
    background-color: #1c1c1c61;
}

.homeBtn {
    padding: 10px 15px 10px 15px;
    margin: 0% 10px 0 10px;
    cursor: pointer;
    width: 150px;
    text-align: center;
    box-shadow: 0px 2px 5px 0px black;
    font-size: 20px;
    position: relative;
}

.homeBtn::before {
    content: "";
    position: absolute;
    top: -12%;
    left: -4%;
    right: 0;
    bottom: 0;
    width: 108%;
    height: 126%;
    border: 1px solid #e0c659;
    border-radius: 0px;
}

.homeBtn:hover {
    transition: 0.3s;
    background-color: #e0c659;
    color: black;
}

.bigT span {
    color: #e0c659;
    font-family: "Vladimir";
    font-size: 70px;
}

.homeINfo {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.singleHomeInf {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-width: 200px;
}

.singleHomeInf span {
    text-align: center;
    font-size: 16px;
    color: white;
}

.singleHomeInf svg {
    margin-bottom: 11px;
    font-size: 45px;
    color: #e0c659;
}
@media(max-width:1000px) {
    .homeINfo {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .singleHomeInf{
        margin-bottom: 50px;
    }
}
@media(max-width:900px) {
    .bigT {
        text-align: center;
        font-size: 50px;
    }

    .mT {
        font-size: 20px;
        text-align: center;
    }

    .right {
        padding-left: 5%;
        padding-right: 5%;
        height: auto;
    }

    .landH {
        width: 100%;
        height: 100%;
        padding-top: 5%;
        display: flex;
        background-color: rgba(0, 0, 0, 0.568);
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .loremTxt {
        width: 80%;
    }
    .aboutCaf {
        text-align: center;
    }
    .secondHomeC{
        padding: 60px 20px 30px 20px;
    }
}

@media(max-width:450px) {
    .bigT {
        line-height: 120%;
    }

    .home {
        height: 100vh;
    }

    .bigT {
        font-size: 40px;
    }

    .mT {
        font-size: 16px;
        text-align: center;
        margin-top: 10%;
    }

    .buttonsH {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        height: 45vw;
    }
    .loremTxt {
        width: 95%;
    }
    .singleHomeInf{
        min-width: 160px;
    }
}