.reservationAD{
    width: 100%;
    min-height: 100vh;
    background-color: #1e1f20;
    background-image: url('../../images/pattern-bg1.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
}
.displayReservation{
    width: 100%;
    min-height: 100vh;
    background-color: #1e1f20;
    background-image: url('../../images/pattern-bg1.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    color: #fff;
    padding: 20px 50px 20px 50px;
}
.menuItemsAdR {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    padding: 50px 0 50px 0;
}

.insidePend {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
}
.pendings {
    width: 100%;
    color: #fff;
    margin-bottom: 40px;
    margin-left: 30px;
}
span.pendingUserss {
    font-size: 20px;
    margin-left: 5px;
    color: #e0c659;
    text-decoration: underline;
}
.absPending {
    position: absolute;
    z-index: 10;
    background-color: red;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    top: 5px;
    right: 9px;
    animation: blink 1s infinite;
}
@keyframes blink {
    0%{
        scale: 1;
    }
    50%{
        scale: 1.1;
    }
    100%{
        scale: 1;
    }
    
}
.relativeDiV {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 67%;
    bottom: 60%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: red;
    border-radius: 52px;
    font-size: 12px;
}
.skicaImgR {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.explanationR {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}
.signelExpp {
    margin: 5px 30px 5px 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.signelExpp span{
    width: auto;
    margin-left: 5px;
    font-size: 14px;
}
.signelExpp:nth-of-type(3) div{
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: red;
}
.signelExpp:nth-of-type(1) div{
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: #edad22;
}
.signelExpp:nth-of-type(2) div{
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: #9d9d9d;
    opacity: 0.7;
}
.reservationSkicaR {
    width: 75%;
    height: auto;
    position: relative;
}
.reservationSkicaR img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.reservationSkicaR span {
    position: absolute !important;
    background-color: #edad22;
    color: black;
    width: 3.2% !important;
    height: 3.2% !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    border: 1px solid black;
}

.reservationSkicaR span:hover {
    scale: 1.1;
    transition: 0.3s;
}

.reservationSkicaR span:nth-of-type(-n+19),
.reservationSkicaR span:nth-of-type(51),
.reservationSkicaR span:nth-of-type(52),
.reservationSkicaR span:nth-of-type(53),
.reservationSkicaR span:nth-of-type(34),
.reservationSkicaR span:nth-of-type(35),
.reservationSkicaR span:nth-of-type(36),
.reservationSkicaR span:nth-of-type(37),
.reservationSkicaR span:nth-of-type(38),
.reservationSkicaR span:nth-of-type(21)
.reservationSkicaR span:nth-of-type(22),
.reservationSkicaR span:nth-of-type(31),
.reservationSkicaR span:nth-of-type(30),
.reservationSkicaR span:nth-of-type(33)
{
    display: none;
}
.reservationSkicaR span:nth-of-type(1) {
    top: 6.4%;
    left: 14%;
}

.reservationSkicaR span:nth-of-type(2) {
    top: 3.3%;
    left: 24.6%;
}

.reservationSkicaR span:nth-of-type(3) {
    top: 3.3%;
    left: 35.3%;
}

.reservationSkicaR span:nth-of-type(4) {
    top: 2.8%;
    left: 49.5%;
}

.reservationSkicaR span:nth-of-type(5) {
    top: 2.8%;
    left: 59%;
}

.reservationSkicaR span:nth-of-type(6) {
    top: 16.5%;
    left: 14%;
}

.reservationSkicaR span:nth-of-type(7) {
    top: 11%;
    left: 24.6%;
}

.reservationSkicaR span:nth-of-type(8) {
    top: 11%;
    left: 35.3%;
}

.reservationSkicaR span:nth-of-type(9) {
    top: 10.4%;
    left: 49.5%;
}

.reservationSkicaR span:nth-of-type(10) {
    top: 10.4%;
    left: 59%;
}

.reservationSkicaR span:nth-of-type(11) {
    top: 10.4%;
    left: 69.4%;
}

.reservationSkicaR span:nth-of-type(12) {
    top: 19%;
    left: 24.6%;
}

.reservationSkicaR span:nth-of-type(13) {
    top: 19%;
    left: 35.3%;
}

.reservationSkicaR span:nth-of-type(14) {
    top: 18%;
    left: 49.5%;
}

.reservationSkicaR span:nth-of-type(15) {
    top: 18%;
    left: 59%;
}

.reservationSkicaR span:nth-of-type(16) {
    top: 18%;
    left: 69.4%;
}

.reservationSkicaR span:nth-of-type(17) {
    top: 27.3%;
    left: 14%;
}

.reservationSkicaR span:nth-of-type(18) {
    top: 27.3%;
    left: 24.6%;
}

.reservationSkicaR span:nth-of-type(19) {
    top: 27.3%;
    left: 35.3%;
}
.reservationSkicaR span:nth-of-type(20) {
top: 41.3%;;
    left: 35.3%;
}
.reservationSkicaR span:nth-of-type(21) {
    top: 41.3%;
    left: 44.4%;
}
.reservationSkicaR span:nth-of-type(22) {
    top: 46.3%;
    left: 12.4%;
}
.reservationSkicaR span:nth-of-type(23) {
    top: 46.3%;
    left: 21%;
}
.reservationSkicaR span:nth-of-type(24) {
    top: 52.3%;
    left: 17.4%;
}
.reservationSkicaR span:nth-of-type(25) {
    top: 52.3%;
    left: 26.6%;
}
.reservationSkicaR span:nth-of-type(26) {
top: 52.3%;
    left: 36.3%;
}
.reservationSkicaR span:nth-of-type(27) {
top: 52.3%;
    left: 45.3%;;
}
.reservationSkicaR span:nth-of-type(28) {
    top: 58.3%;
    left: 30.3%;
}
.reservationSkicaR span:nth-of-type(29) {
    top: 58.3%;
        left: 40.3%;
}
.reservationSkicaR span:nth-of-type(30) {
    top: 58.3%;
    left: 49.3%;
}
.reservationSkicaR span:nth-of-type(31) {
        top: 65.3%;
    left: 30.3%;
}
.reservationSkicaR span:nth-of-type(32) {
        top: 65.3%;
        left: 40.3%;
}
.reservationSkicaR span:nth-of-type(33) {
        top: 65.3%;
    left: 49.3%;
}

.reservationSkicaR span:nth-of-type(34) {
    top: 55.5%;
    left: 63.8%;
}
.reservationSkicaR span:nth-of-type(35) {
        top: 65.5%;
    left: 63.8%;
}
.reservationSkicaR span:nth-of-type(36) {
    top: 59%;
    left: 72%;
}
.reservationSkicaR span:nth-of-type(37) {
    top: 59%;
    left: 85%;
}
.reservationSkicaR span:nth-of-type(38) {
    top: 59%;
    left: 93.8%;
}
.reservationSkicaR span:nth-of-type(39) {
    top: 71.3%;
    left: 10.6%;
}
.reservationSkicaR span:nth-of-type(40) {
    top: 71.3%;
    left: 18.4%;
}
.reservationSkicaR span:nth-of-type(41) {
top: 79.8%;
    left: 10.6%;
}
.reservationSkicaR span:nth-of-type(42) {
top: 79.8%;
    left: 18.4%;
}
.reservationSkicaR span:nth-of-type(43) {
top: 79.8%;
        left: 25.5%;
}
.reservationSkicaR span:nth-of-type(44) {
top: 86.3%;
    left: 10.6%;
}
.reservationSkicaR span:nth-of-type(45) {
top: 86.3%;
    left: 18.4%;
}
.reservationSkicaR span:nth-of-type(46) {
top: 86.3%;
        left: 25.5%;
}
.reservationSkicaR span:nth-of-type(47) {
top: 79.8%;
    left: 34%;
}
.reservationSkicaR span:nth-of-type(48) {
top: 86.3%;
    left: 34%;
}
.reservationSkicaR span:nth-of-type(49) {
    top: 74.5%;
    left: 43.1%;
}
.reservationSkicaR span:nth-of-type(50) {
    top: 80.8%;
    left: 43.1%;
}
.reservationSkicaR span:nth-of-type(51) {
    top: 27.8%;
    left: 48.4%;
    min-width: 18%;
    min-height: 4%;
}
.reservationSkicaR span:nth-of-type(52) {
    top: 30.5%;
    left: 70%;
    min-height: 18%;
    min-width: 3.8%;
    writing-mode: vertical-rl;
    text-orientation: upright;
}
.reservationSkicaR span:nth-of-type(53) {
    top: 28.9%;
    left: 91.4%;
    min-height: 18%;
    min-width: 5.3%;
    writing-mode: vertical-rl;
    text-orientation: upright;
}

.reservationSkicaR span:nth-of-type(54) {
    top: 58.3%;
    left: 11.4%;
    min-width: 5%;
}

.reservationSkicaR span:nth-of-type(55) {
    top: 58.3%;
    left: 20.4%;
    min-width: 5%;
}

.gostiInv {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #93939352;
    padding-bottom: 10px;
    justify-content: space-between;
}
.gostiInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.gostiInfoBTN{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem
}

.sub_gosti{
    font-size: .7rem;
    color: #4f4f4fa3;
}
.gostiInfo div{
    font-size: 14px;
    margin: 0 15px 0 15px;
}
.bookUser {
    width: auto;
    padding: 5px 20px;
    background: #4f4f4f;
    color: WHITE;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}
.formsEventR {
    margin-top: 50px;
    margin-bottom: 50px;
    height: 380px;
    overflow-y: auto;
    scrollbar-color: dark; /* For Firefox */
    scrollbar-width: thin; /* For Firefox */
}

.formsEventR::-webkit-scrollbar {
    width: 8px; /* For WebKit browsers */
}

.formsEventR::-webkit-scrollbar-thumb {
    background-color: transparent; /* or background-color: rgba(0, 0, 0, 0); */
}
.reservedTTT {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
.reservedTTT div{
    width: auto;
    padding: 5px 20px;
    background: #4f4f4f;
    color: WHITE;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}
@media(max-width:1000px) {
    .reservationSkicaR {
        width: 100%;
    }
    .gostiInfo div {
       
        margin: 0 0px 0 0px;
    }
    .displayReservation{
        padding: 20px 50px 20px 50px;
    }
    .gostiInfo {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .gostiInfo div{
        width: 100%!important;
        text-align: center;
    }
    .gostiInfo div:last-of-type{
        margin-bottom: 20px;
    }
}
@media(max-width:700px) {
    .displayReservation{
        padding: 20px 20px 20px 20px;
    }
    .reservationSkicaR {
        width: 142%;
    }
    .skicaImgR{
        overflow: hidden;
    }
    .signelExpp {
        margin: 5px 30px 20px 30px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
}
@media(max-width:500px) {
    .reservedTTT {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .reservedTTT span{
        width: 100%!important;
        text-align: center;
        margin-bottom: 13px;
    }
}
@media(max-width:450px) {
    .displayReservation {
        padding: 20px 10px 20px 10px;
    }
    .reservationSkicaR span{
        width: 20px !important;
    height: 20px !important;
    font-size: 12px;
    }
    .relativeDiV{
        font-size: 10px;
        width: 17px;
    height: 17px;
    }
}
