.about{
    padding-top: 65px;
    width: 100%;
    min-height: 100vh;
}
.aboutUsS{
    background-image: url('../images/aboutUs.jpg');
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.titleAbout {
    color: #d8d8d8;
    font-size: 50px;
    font-weight: 500;
    position: relative;
}
.titleAbout::before {
    content: "";
    width: 70%;
    height: 2px;
    top: 95%;
    /* background: linear-gradient(0deg, #e3c48e 0%, rgb(18 74 35) 10%, #e3c48e 95%); */
    position: absolute;
    left: 15%;
    background: linear-gradient(90deg, rgb(116 116 116 / 41%) 0%, #edd15e 35%, #edd15e 65%, rgb(116 116 116 / 41%) 100%);
}
.aboutContent{
    background-color: #1c1c1c;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column; 
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.textF {
    font-size: 14px;
    text-align: center;
}
.titleF {
    font-size: 20px;
    margin-bottom: 16px;
}
.firstCabout {
    width: 65%;
    color: #d8d8d8;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 40px 0 40px 0;
}
.aboutImgOne {
    width: 400px;
    height: 500px;
    margin-right: -75px;
    margin-top: 211px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    z-index: 10;
    background-color: #3e3e3e;
}
.imgAbImg {
    width: 100%;
    height: 67%;
    overflow: hidden;
    border-radius: 5px;
}
.imgAbImg:hover img{
    scale: 1.1;
    transition: 0.3s;
}
.imgAbImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.aboutImgTwo {
    padding: 20px 0 20px 0;
    text-align: center;
    font-size: 14px;
    width: 500px;
    height: 500px;
    z-index: 5;
}
.aboutImgTwo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.secondCabout {
    width: 100%;
    height: auto;
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
}
.aboutImgText {
    padding: 20px;
    text-align: center;
    font-size: 14px;
    color: #d8d8d8;
}
.aboutImgOneT {
    width: 400px;
    height: 500px;
    margin-left: -75px;
    margin-top: 211px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    z-index: 10;
    position: relative;
    background-color: #3e3e3e;
}
.thirdCabout {
    width: 100%;
    height: auto;
    color: white;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 100px;
}
.aboutImgOneT::before {
    content: "";
    width: 50%;
    height: 2px;
    top: -3%;
    position: absolute;
    left: 25%;
    background: linear-gradient(90deg, rgb(21 32 21) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%, rgb(21 32 21) 100%);
}
.aboutImgOneT::after {
    content: "";
    width: 30%;
    height: 2px;
    top: -5%;
    position: absolute;
    left: 35%;
    background: linear-gradient(90deg, rgb(21 32 21) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%, rgb(21 32 21) 100%);
}
.aboutImgOne::before {
    content: "";
    width: 50%;
    height: 2px;
    top: -3%;
    position: absolute;
    left: 25%;
    background: linear-gradient(90deg, rgb(21 32 21) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%, rgb(21 32 21) 100%);
}
.aboutImgOne::after {
    content: "";
    width: 30%;
    height: 2px;
    top: -5%;
    position: absolute;
    left: 35%;
    background: linear-gradient(90deg, rgb(21 32 21) 0%, rgb(18 74 35) 35%, rgb(18 74 35) 65%, rgb(21 32 21) 100%);
}
@media(max-width:450px) {
    .titleAbout {
        font-size: 30px;
    }
}
@media(max-width:1000px) {
    .secondCabout {
        width: 100%;
        height: auto;
        color: white;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .aboutImgOne {
        width: 400px;
        height: 500px;
        margin-right: 0; 
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;
        border-radius: 5px;
        position: relative;
        z-index: 10;
        background-color: #3e3e3e;
    }
    .aboutImgTwo {
        padding: 20px 0 20px 0;
        text-align: center;
        font-size: 14px;
        margin-top: -78px;
        width: 500px;
        height: 500px;
        z-index: 5;
    }
    .thirdCabout {
        width: 100%;
        height: auto;
        color: white;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 100px;
    }
    .aboutImgOneT{
        margin-left: 0px;
    }
   
}
@media(max-width:600px) {
    .firstCabout {
        width: 80%;
    }
    .aboutImgTwo {
        width: 90%;
    }
    .aboutImgOne {
        width: 70%;
    }
}
@media(max-width:450px) {
    .aboutImgOne {
        width: 85%;
    }
    .aboutImgOneT{
        width: 85%;
    }
    .firstCabout {
        width: 95%;
    }
}