.menuAdmin{
    width: 100%;
    min-height: 100vh;
    background-color: #1e1f20;
    background-image: url('../../images/pattern-bg1.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
}
.displayMenu{
    width: 100%;
    min-height: 100vh;
    background-color: #1e1f20;
    background-image: url('../../images/pattern-bg1.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    padding: 20px 50px 20px 50px;
    color: #fff;
}

.reg_txt{
    color: #212529a9 !important;
}
.clickable_m:hover{
    text-decoration: underline;
    cursor: pointer;
    color: #e0c659;
}
.center__err{
    width: 100%;
    text-align: center;
}
.menu_create_group > label.form-label {
    color: #212529 !important;
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
}
.mainDivAdm {
    color: #d8d8d8;
    padding: 20px;
    padding: 20px 50px 20px 50px;
}
.formINpou {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 50px;
}
.brnDisplaMenuI {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
}

button#dropdownN {
    width: 120px;
    margin-right: 20px;
    background: #e0c659;
    color: black;
    /* color: #fff; */
    border: none;
    border-radius: 10px;
    font-size: 14px;
}
.formINpou form{
    width: 700px;
}
.mainDivAdm span{
    color: white;
}
.itemNnn {
    padding: 50px 0 5px 20px;
    width: 100%;
    border-bottom: 1px solid grey;
    color: #fff;
}

.svgI svg {
    color: #fff;
    font-size: 40px;
}

.bg_blue{
    background-color: #7293e9 !important; 
}

.bg_red{
    background-color: #e97272 !important; 
}

.btn__group{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    width: 100%;
    gap: 1rem;
}

.menuBoxSelect{
    padding: 1rem;
    background: #ffffff14;
    border-radius: 10px;
    color: white;
    border: 1px solid #ffffff6b;
    cursor: pointer;

    width: 45%;
    text-align: center;
}
.menuBoxSelect:hover{
    background: #ffffff2f;
}
button#dropdown-basic {
    width: fit-content;
    margin-right: 20px;
    background: rgba(255,255,255,.1);
    color: #fff;
    border: none;
    border-radius: 100px;
    font-size: 14px;
}
.filterMenuAndItems {
    padding: 20px 50px 20px 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}
button#dropdown-basic svg{
    margin-left: 3px;
    font-size: 20px;
}
.imgADm img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.singleMenuAd {
    width: 340px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: #d8d8d8;
    border-radius: 5px;
    background-color: #2f3133;
    overflow: hidden;
    position: relative;
    z-index: 3;
    margin: 0 15px 40px 15px;
}
button#dropdownNNN {
    width: 120px;
    margin-right: 20px;
    background: #4f4f4f;
    color: WHITE;
    /* color: #fff; */
    border: none;
    border-radius: 10px;
    font-size: 14px;
}
.descripsionMenuAd {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.topD {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.topD span{
    width: auto;
    font-size: 16px;
}
.topD span:last-of-type {
    font-size: 20px;
    color: #e0c659;
}
.changeItemAd {
    width: 100%;
    text-align: center;
    background-color: #e0c659;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 0;
    margin-top: 25px;
}
.descripTextAD {
    margin-top: 10px;
    font-size: 14px;
    width: 100%;
}
.menuItemsAd {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    padding: 50px 0 50px 0;
}
.filteredMenu {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.dropdown-toggle::after {
    margin-left: 0;
    right: 10px;
    position: absolute;
    top: 23px;
    opacity: 0;
}
.dropdown-menu.show {
    background-color: #242424!important;
    border-radius: 13px;
}
.dropdown-item{
    width: 92%;
}
a.dropdown-item:hover {
    background-color: #464646!important;
    border-radius: 13px;
}
.selectedCategiryN {
    padding: 50px 0 5px 20px;
    width: 100%;
    border-bottom: 1px solid grey;
    color: #fff;
}
select.form-select {
    margin-bottom: 18px;
}
@media(max-width:1000px) {
    .formINpou form {
        width: 100%;
    }
}
@media(max-width:700px) {
    .displayMenu{
        padding: 20px 10px 20px 10px;
    }
}
@media(max-width:500px) {
    .formINpou{
        padding: 50px 10px;
    }
}
@media(max-width:450px) {
    .filterMenuAndItems {
        padding: 20px 5px 20px 5px ;
    }
    .filteredMenu {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .mainDivAdm {
        color: #d8d8d8; 
        padding: 20px;
        padding: 20px 5px 20px 10px;
    }
}